<template>
  <h6 class="text-subtitle2 q-ml-lg text-uppercase text-grey-8">
    {{ $t("login.welcome", { name: partner.name }) }}
  </h6>
  <Form
    class="q-gutter-md"
    :initial-values="initialValues"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <q-card-section>
      <div>
        <Field name="email" v-slot="{ errorMessage, value, field }">
          <q-input
            autocomplete="on"
            filled
            :label="labels.email"
            :placeholder="$t('forms.placeholders.email')"
            :model-value="value"
            v-bind="field"
            :error-message="errorMessage"
            :error="!!errorMessage"
          />
        </Field>
      </div>
      <div class="q-mt-sm">
        <Field name="password" v-slot="{ errorMessage, value, field }">
          <q-input
            type="password"
            filled
            autocomplete="on"
            :label="labels.password"
            :model-value="value"
            v-bind="field"
            :error-message="errorMessage"
            :error="!!errorMessage"
          />
        </Field>
      </div>
      <div v-if="recaptchaErrorMessage">
        <p class="q-py-sm q-mt-lg q-mb-none">
          <span v-html="$t('login.problemLoggingAccount')" />
          <a
            @click="refreshThePage"
            style="
              cursor: pointer;
              text-decoration: underline;
              margin-left: 5px;
            "
            >Tente atualizar a página</a
          >.
        </p>
      </div>
      <q-btn
        class="q-mt-md full-width no-shadow"
        color="dark"
        unelevated
        type="submit"
        :label="$t('login.submit')"
        :loading="in_request"
        :disable="in_request"
      />
    </q-card-section>
  </Form>
  <!--
  <social-login />
  -->
  <q-card-section class="text-center q-pa-lg">
    <q-item :to="{ name: 'recover' }">
      <q-item-label>
        {{ $t("login.forgetPassword") }}
      </q-item-label>
    </q-item>
    <q-item
      @click="redirectToSignUp()"
      clickable
      v-if="hasOtherSignUp != 'not_avaible'"
    >
      <q-item-label>
        {{ $t("login.createAccount") }}
      </q-item-label>
    </q-item>
  </q-card-section>

  <q-dialog v-model="disapprovedModal">
    <q-card style="width: 480px !important">
      <q-card-section class="row q-pb-none">
        <q-img
          :src="partner.logo"
          class="q-mr-auto q-ml-auto"
          style="width: 180px"
        />
      </q-card-section>
      <q-card-section class="q-ma-md q-pa-md default-rounded bg-grey-3">
        <div class="q-mt-md q-mb-sm" v-html="disapprovedMsg" />
      </q-card-section>
      <div class="q-mt-md q-mb-sm q-ma-md">
        Ficou com dúvidas? Entre em contato com
        <b class="text-blue-8">suporte@flip.net.br</b>
      </div>

      <q-card-actions align="right" class="q-pt-none">
        <q-btn flat label="Fechar" color="black" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { getUtmParams, deleteUtmParams } from "@/shared/helpers/utmHelper";
import { getLoginRoute, deleteLoginRoute } from "../../partner/storage";
//import SocialLogin from "@/modules/auth/pages/components/social-login";
import { useActions, useGetters } from "vuex-composition-helpers";
import { setCustomLayout } from "@/shared/helpers/customLayoutHelper";
import { showFailMessages } from "@/shared/helpers/notify";
import { rules, labels } from "@/shared/form-presets";
import { ref, onMounted, defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";
import { baseURL } from "@/shared/http/api";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "Login",

  components: {
    // SocialLogin,
    Field,
    Form,
  },

  setup() {
    const router = useRouter(),
      route = useRoute(),
      in_request = ref(false),
      disapprovedModal = ref(false),
      disapprovedMsg = ref(""),
      hasLoginRoute = ref(""),
      recaptchaErrorMessage = ref(false),
      hasOtherSignUp = ref("application");

    const refreshThePage = () => location.reload();

    const schema = (() => {
      const { emailLogin, password } = rules;
      return yup.object({ email: emailLogin, password });
    })();

    const urlParams = new URLSearchParams(window.location.search),
      initialValues = { email: urlParams.get("email") };

    const { signInAction } = useActions({
        signInAction: "auth/signInAction",
      }),
      { partner } = useGetters({
        partner: "partner/getPartner",
      });

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const onSubmit = async ({ email, password }, actions) => {
      await recaptchaLoaded();
      let token = await executeRecaptcha("login");

      in_request.value = true;
      signInAction({
        email,
        senha: password,
        slug: route.params.slug,
        recaptcha_response: { token }.token,
      })
        .then(() => {
          deleteUtmParams();
          if (hasLoginRoute.value) {
            router.push({ path: hasLoginRoute.value });
            deleteLoginRoute();
          } else router.push({ name: "dashboard" });
        })
        .catch((error) => {
          if ("reproved" in error.errors) {
            disapprovedMsg.value = error.errors.reproved[0];
            disapprovedModal.value = true;
          } else if ("analysis" in error.errors) {
            disapprovedMsg.value = error.errors.analysis[0];
            disapprovedModal.value = true;
          } else {
            showFailMessages(error.errors);
            actions.setFieldValue("password", "");
            const isRecaptchaError = error.errors?.recaptcha_response;
            recaptchaErrorMessage.value =
              isRecaptchaError.length > 0 &&
              isRecaptchaError[0].includes("Recaptcha")
                ? true
                : false;
          }
        })
        .finally(() => (in_request.value = false));
    };

    const redirectToSignUp = () => {
      if (
        hasOtherSignUp.value !== "application" &&
        hasOtherSignUp.value !== "signup-v2"
      ) {
        window.open(hasOtherSignUp.value, "_blank");
        return;
      }

      const utmParams = getUtmParams(),
        routeConf = {
          name: hasOtherSignUp.value == "signup-v2" ? "sign.up-v2" : "sign.up",
        };

      if (utmParams) routeConf["query"] = { ...JSON.parse(utmParams) };
      router.push(routeConf);
    };

    const fetchParam = (param) =>
      axios.get(`${baseURL}/api/v1/parameters/p/${route.params.slug}/${param}`);

    onMounted(() => {
      fetchParam("refirect_signup").then(
        (res) => (hasOtherSignUp.value = res.data.value)
      );

      fetchParam("custom_layout").then(({ data }) => {
        setCustomLayout(data.value);
      });

      const loginRoute = getLoginRoute();
      if (loginRoute) hasLoginRoute.value = loginRoute;
      if (!Object.keys(partner.value).length) router.push({ name: "404" });
    });

    return {
      refreshThePage,
      recaptchaErrorMessage,
      labels,
      schema,
      partner,
      in_request,
      initialValues,
      hasOtherSignUp,
      disapprovedMsg,
      disapprovedModal,
      onSubmit,
      redirectToSignUp,
    };
  },
});
</script>
<style lang="scss" scoped>
.mobile {
  .auth {
    .q-item {
      padding: 0px;
    }
  }
}
</style>
